.section {
  &__title {
    font-size: 21px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid $dark;
  }
}

.product-menu {
  background-color: #f3f3f3;

  & > .menu-item {
    margin: 0;

    & > .menu-link {
      font-size: 16px;
      color: #666;

      &:hover,
      &.active {
        color: $white;
        background-color: $dark;
      }
    }
  }
}

.product {
  padding: 20px;

  &__iwrap {
    @include iwrap(56.25%);

    img {
      object-fit: contain;
    }
  }

  &__title {
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 0 0;
    margin-bottom: 0;
  }

  @media (max-width: 575px) {
    padding: 20px 0;
  }
}

.product-slider {
  .swiper-pagination-bullet {
    width: 13px;
    height: 13px;
    border: 1px solid $white;
    margin: 0 10px;
  }

  .swiper-pagination-bullet-active {
    background-color: $orange;
    border-color: $orange;
  }

  .swiper-slide {
    @include iwrap(56.25%);
    max-height: calc(100vh - 40px);

    img {
      top: 10%;
      left: 10%;
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }
}

.download-catalogue {
  display: inline-flex;
  align-items: center;

  img {
    height: 24px;
  }
}

.post {
  .post-title {
    font-size: 25px;
    text-transform: uppercase;
  }

  .post-heading {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      flex: 1;
      border-top: 1px solid $dark;
      margin-left: 6px;
    }
  }
}
