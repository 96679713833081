.footer {
  background-color: #1a1a1a;
  color: $white;
  padding-top: 26px;

  &__container {
    width: 80%;
    max-width: 1480px;
    margin: 0 auto;
  }

  &__text {
    width: 80%;
    margin: 0 auto;
    padding: 14px 0 50px;
    text-align: center;
    font-size: 12px;
    color: #515151;
  }

  @media (max-width: 767px) {
    &__text {
      font-size: 10px;
      padding: 12px 0 40px;
    }
  }
}

.f-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0 -10px;
  padding-left: 0;

  &__item {
    padding: 10px;
  }

  &__link {
    display: flex;
    width: 55px;
    height: 55px;
    justify-content: center;
    align-items: center;
    background-color: #484848;
    color: $dark;
    border-radius: 50%;
    transition: 0.3s;
    position: relative;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    .fa {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #484848;
      border-radius: 50%;
      color: #1a1a1a;
    }

    &:hover .fa-facebook {
      background-color: #37538d;
    }

    &:hover .fa-twitter {
      background-color: #47a5ed;
    }

    &:hover .fa-youtube {
      background-color: #e73a35;
    }
  }
}

.f-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  padding: 13px 0 5px;
  border-bottom: 1px solid #e5e5e5;

  &__item {
    &:not(:last-child) {
      margin-right: 18px;
    }
  }

  &__link {
    font-size: 14px;
    text-transform: uppercase;
    color: #9f9f9f;
    transition: 0.3s;

    &:hover {
      color: #efefef;
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    padding-top: 8px;

    &__item {
      &:not(:last-child) {
        margin-right: 11px;
      }
    }

    &__link {
      font-size: 10px;
    }
  }
}
