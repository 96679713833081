.home-container {
  width: 960px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.banner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    padding: 20px 2px;
    color: $white;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: -26px;
    cursor: pointer;
    opacity: 0;
    transition: 0.3s;

    &:hover {
      background-color: rgba(#000, 0.5);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }

  &:hover &__prev,
  &:hover &__next {
    opacity: 1;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 14px 0 32px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      border: 2px solid $black;
      background: 0;
      opacity: 1;
      margin: 0 4px;

      &:focus {
        outline: 0;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: $black;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;

    &__pagination {
      padding: 14px 0 0;

      .swiper-pagination-bullet {
        width: 18px;
        height: 18px;
        border: 2px solid $black;
        margin: 0 4px;

        &-active {
          background-color: $black;
        }
      }
    }
  }
}
