.header {
  position: relative;
  z-index: 100;
  background-color: $dark;

  &__container {
    width: 80%;
    max-width: 1480px;
    margin: 0 auto;
  }

  &__inner {
    // display: flex;
    // align-items: center;
    position: relative;
  }

  &__logo {
    display: inline-flex;
    padding: 14px 0;
    margin: 0 auto 0 0;

    img {
      height: 70px;
    }
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 31px;
    background: 0;
    border: 1px solid $border-color;
    font-size: 16px;
    border-radius: 4px;
    background-color: #fff;
    color: $black;
    padding: 0;
    position: absolute;
    top: 49px;
    right: 0;
    transform: translateY(-50%);
  }

  @media (min-width: 992px) {
    // &__inner {
    // display: flex;
    // align-items: flex-end;
    // padding: 0;
    // }

    &__logo {
      margin-left: 0;
    }

    &__btn {
      display: none;
    }
  }
}

.navbar {
  @media (min-width: 992px) {
    align-items: flex-end;
  }
}

.menu-root {
  @media (max-width: 991px) {
    padding-bottom: 30px !important;

    .menu-item {
      margin: 10px 0 0;
    }

    .menu-link {
      color: #9f9f9f;
      border: 1px solid;
      padding: 2px 13px 0 !important;
    }

    & > .menu-item > .menu-link {
      text-transform: uppercase;
    }

    .menu-toggle {
      height: 44px;
      font-size: 18px;
      color: #9f9f9f;
    }

    .menu-sub {
      margin-left: 30px;
    }

    .menu-sub-2 {
      margin-left: 60px;
    }

    .menu-sub-3 {
      margin-left: 90px;
    }
  }

  @media (min-width: 992px) {
    width: 100%;
    justify-content: flex-end;

    & > .menu-item {
      & > .menu-link {
        padding: 0 16px;
        color: #dad7d7;
        text-transform: uppercase;

        &.active {
          color: $white;
          text-decoration: underline;
        }
      }

      &:hover > .menu-link {
        color: $white;
        text-decoration: underline;
        background: 0;
      }

      &.menu-item-group > .menu-link {
        padding-right: 16px;
      }

      &.menu-item-group > .menu-toggle {
        display: none;
      }
    }

    .menu-sub:not(.menu-sub-2) {
      & .menu-item {
        margin: 0;
        padding: 4px 0;
      }

      & > .menu-item-group {
        position: static;

        & > .menu-link {
          position: relative;

          &::after {
            content: '\f101';
            font-family: 'fontawesome';
            position: absolute;
            top: 0;
            right: 10px;
          }
        }

        & > .menu-toggle {
          display: none;
        }

        & > .menu-sub-2 {
          flex-wrap: wrap;
          min-height: 100%;
          align-items: flex-start;
          align-content: flex-start;
          min-width: 320px;
          top: 0;
          left: 100%;
          box-shadow: unset;
          background-color: #f6f6f6;
          padding: 20px 0;

          .menu-item {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 4px 20px;
          }

          .menu-link {
            display: inline;
            line-height: 20px;
            font-size: 14px;
            padding: 0;
            overflow: unset;
            white-space: normal;
            font-weight: 600;

            &:hover {
              color: $orange !important;
            }
          }

          .menu-item:hover > .menu-link {
            background: 0;
            color: inherit;
          }
        }

        &:hover > .menu-sub-2 {
          display: flex;
        }
      }
    }
  }
}

.search {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.925);
  position: fixed;
  z-index: 2030;
  top: 0;
  left: 0;

  &__inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__label {
    font-size: 21px;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
  }

  &__input {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 85px;
    line-height: 1;
    text-transform: uppercase;
    background: 0;
    border: 0;
  }

  &__btn {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  @media (max-width: 767px) {
    &__label {
      font-size: 12px;
      margin-bottom: 10px;
    }

    &__input {
      font-size: 50px;
    }
  }
}
