.contact {
  &__content {
    .contact-header {
      font-size: 16px;
      font-weight: 700;

      &-line {
        display: flex;
        align-items: center;

        &::after {
          content: '';
          display: block;
          flex: 1;
          border-top: 1px solid $dark;
          margin-left: 10px;
        }
      }
    }

    a,
    a:hover {
      color: inherit;
    }

    dt {
      font-size: 16px;
      font-weight: 700;
      padding-left: 20px;
      margin-bottom: 10px;
      margin-top: 26px;
    }

    dd {
      padding-left: 80px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
