.page-item {
  &:not(:last-child) {
    margin-right: 14px;
  }

  &.active .page-link {
    background-color: $gray-800;
    border-color: $gray-800;
  }

  .page-link {
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px 16px;
    font-size: 20px;
    font-weight: 700;
    color: #f58634 !important;
    border-radius: 50% !important;
    background-color: $dark !important;
  }

  &.active .page-link {
    background-color: #f58634 !important;
    border-color: #f58634 !important;
    color: $dark !important;
  }

  @media (max-width: 575px) {
    .page-link {
      width: 35px;
      height: 35px;
      font-size: 16px;
    }
  }
}
