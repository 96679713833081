.intro {
  &-title {
    text-align: center;
    position: relative;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 20px;

    &::before {
      content: '';
      display: block;
      width: 90px;
      height: 6px;
      background-color: $dark;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
