@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900&subset=vietnamese');
$roboto: 'Roboto', sans-serif;

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.eot');
  src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato-Regular.ttf') format('truetype'),
    url('../fonts/Lato-Regular.svg#svgFontName') format('svg');
  font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.eot');
  src: url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-Bold.woff') format('woff'),
    url('../fonts/Lato-Bold.ttf') format('truetype'),
    url('../fonts/Lato-Bold.svg#svgFontName') format('svg');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Black.eot');
  src: url('../fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-Black.woff') format('woff'),
    url('../fonts/Lato-Black.ttf') format('truetype'),
    url('../fonts/Lato-Black.svg#svgFontName') format('svg');
  font-weight: 900;
}

$lato: 'Lato', sans-serif;
