.news-list {
  padding-left: 17px;

  &__item {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__link {
    @include max-line(1);
    color: $gray-800;

    &:hover,
    &.active {
      color: $orange;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 17px;
    border-bottom: 3px solid #666666;
  }
}

.news {
  padding: 0 20px;
  border-left: 1px solid $dark;
  border-right: 1px solid $dark;

  &-title {
    font-size: 21px;
    text-align: center;
    margin-bottom: 16px;
  }

  @media (max-width: 991px) {
    padding: 0;
    border-left: 0;
    border-right: 0;
  }
}
