.category {
  overflow: hidden;

  &__title {
    font-size: 21px;
    margin-bottom: 0;
    padding: 16px 0 2px;
  }

  &__body {
    position: relative;
  }

  &__iwrap {
    @include iwrap(65%);
  }

  &__overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    padding: 30px;
    background-color: rgba(#000, 0.7);
    color: $white;
    text-align: center;
    transition: 0.4s;
    opacity: 0;

    &:hover {
      color: $white;
      text-decoration: none;
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    &__title {
      color: #3f3f3f;
    }
  }
}
